<template>
    <div class="goods-item" v-if="Object.keys(goods).length > 0">
        <img class="custom-img" :src="goods.goods_image || defaultGoodsImg" alt="商品" :title="goods.goods_name" @click="toDetail" @error="imgError">
        <div class="info-box">
            <div class="goods-name" v-if="goods.goods_name" @click="toDetail">{{ goods.goods_name }}</div>
            <div class="goods-name" v-else-if="goods.good_title" @click="toDetail">{{ goods.good_title }}</div>
            <div class="price-box">
                <div class="price">单价：<span class="money">{{ goods.goods_price }}</span></div>
                <div class="promotion-price">折扣：<span class="money">{{ goods.goods_promotion_price }}</span></div>
            </div>
        </div>
        <div class="operation">
            <i class="icon el-icon-copy-document" @click="copy"></i>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoodsItem',
    components: {},
    props: {
        goods: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            defaultGoodsImg: require("../../../../assets/images/newService/default_goods.png")
        }
    },
    computed: {},
    methods: {
        toDetail() {
            const path = this.$router.resolve({
                path: "/student/kefugoods",
                query: {
                    r: this.goods.goods_id
                }
            });
            window.open(path.href, "_blank");
        },
        copy() {
            const goodsName = this.goods.goods_name || this.goods.good_title;
            navigator.clipboard.writeText(goodsName).then(() => this.$message.success("复制成功")).catch(() => this.$message.error("复制失败"));
        },
        imgError(e) {
            e.target.src = this.defaultGoodsImg;
        }
    }
}
</script>


<style scoped lang="scss">
$themeColor: #006AFF;
.goods-item {
    box-sizing: border-box;
    display: flex;
    max-width: 100%;
    height: 76px;
    padding: 8px 6px;
    margin-bottom: 16px;
    font-size: 12px;
    color: #606266;
    background-color: #f7f6fb;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    &:last-child {
        margin-bottom: 0;
    }
    .custom-img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        vertical-align: middle;
        cursor: pointer;
    }
    .info-box {
        flex: 1;
        overflow: hidden;
        .goods-name {
            width: 100%;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
        .price-box {
            display: flex;
            margin-top: 6px;
            .price {
                margin-right: 20px;
            }
            .money {
                color: #EC3535;
            }
        }
    }
    .operation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .icon {
            font-size: 14px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: $themeColor;
            }
        }
        
    }
}
</style>
