<template>
    <div class="customer-service" v-if="Object.keys(coreInfo).length > 0" v-loading="loading">
        <TopBar :user-info="userInfo" @submitPaper="submitPaper" />
        <div class="containers">
            <ClientListSidebar :robot-info="robotList" :wait-time="coreInfo.wait_time" :robot-index.sync="robotIndex" :answer-status="answerStatus" />
            <div class="main">
                <ChatWindow 
                    ref="chatWindow"
                    :current-robot="robotList[robotIndex]" 
                    :response-time="coreInfo.response_time" 
                    :answer-status="answerStatus" 
                    :reply-list="replyList"
                    :user-info="userInfo"
                    @startAnswer="startAnswer"
                    @setChatMessage="setReplyChatMessage"
                />
                <FastReply :reply-list="replyList" @setReplyList="(list) => replyList = list" @setChatMessage="setReplyChatMessage" :answer-status="answerStatus" />
                <OperationBar :robot="robotList[robotIndex]" :answer-status="answerStatus" />
            </div>
        </div>
    </div>
</template>

<script>
import { validateRes, validateQuestionType, formatTime, isDev,
        getNewQuestion } from "./library"

import TopBar from "components/student/customerServiceNew/TopBar"
import ClientListSidebar from "components/student/customerServiceNew/ClientListSidebar"
import ChatWindow from "components/student/customerServiceNew/ChatWindow"
import FastReply from "components/student/customerServiceNew/FastReply"
import OperationBar from "components/student/customerServiceNew/OperationBar"
import { getStudentServiceInfoAPI, studentServiceSubmitRobotAPI, getStudentHistoryInfoAPI, studentUsePaperStatus, studentServiceSubmitUnfinishedRobot } from "../../../utils/apis"
let _this;

export default {
    name: 'CustomerService',
    components: {
        TopBar,
        ClientListSidebar,
        ChatWindow,
        FastReply,
        OperationBar
    },
    data() {
        return {
            loading: false,
            defaultAvatar: require("../../../assets/images/newService/default_avatar.png"),
            userInfo: {
                username: localStorage.getItem("username"),
                avatar: localStorage.getItem("headerImg") || this.defaultAvatar,
                // avatar: require("../../../assets/images/newService/default_avatar.png"),
                studentId: localStorage.getItem("studentID"),
            },
            coreInfo: {},
            robotIndex: 0,
            answerStatus: {
                beBing: false,
                status: 0
            }, //是否开始考试/训练 1考试中，2考试结束
            replyList: [],
            historyList: [],
            timer: {
                robotShowTimer: void 0
            }
        }
    },
    provide: {
        validateRes,
        validateQuestionType,
        formatTime,
        isDev,
    },
    computed: {
        robotList() {
            if(!this.coreInfo.robot_info || !this.answerStatus.beBing) return this.historyList;
            return this.coreInfo.robot_info.filter(item => item.chatConfig.isShow).concat(this.historyList);
        }
    },
    methods: {
        init() {
            this.getAnswerStatus();
            this.getStudentServiceInfo();
            this.getStudentHistoryData();
        },
        async getStudentHistoryData() {
            const res = await getStudentHistoryInfoAPI();
            if(!validateRes(res) || res.data.length <= 0) return void 0;
            res.data.robot_info.forEach((item, index) => {
                item.avatar = this.defaultAvatar;
                item.chatConfig = {
                    isShow: true,
                    finished: true,
                    template: item.question.map(QItem => QItem.stu_answer),
                    sendMsg() {
                        _this.$message.warning("机器人已下线无法再作答")
                    }
                }
            })
            this.historyList = res.data.robot_info;
        },
        async getStudentServiceInfo() {
            const res = await getStudentServiceInfoAPI();
            if(!validateRes(res)) return void 0;
            const getAnswerTime = (question, answer) => Math.floor((new Date(answer).getTime() - new Date(question).getTime()) / 1000);
            const validateFixedCoupon = (question, answer) => Object.keys(question.coupon_answer).every(key => question.coupon_answer[key] == answer.coupon_info[key]);
            const validateCreateCoupon = (question, answer) =>  ["coupon_id", "price_jian", "price_man", "validity_time", "good_id"].every(key => question[key] == answer[key])
            const validateGoods = (question, answer) => question.good_info.goods_id == answer.good_id;
            const validateCompensation = (question, answer) => question.reason == answer.reason && question.money == answer.money;
            const validateCheckOrder = (question, answer) => true;
            const validateAnswer = (question, answer) => {
                const packIncludes = (arr) => arr.some(item => answer.includes(item));
                // return !packIncludes(res.data.banned_word) && !packIncludes(question.no_score_keyword) && packIncludes(question.score_operate);
                return packIncludes(question.score_operate);
            };
            const validateMap = {
                1: validateFixedCoupon,
                2: validateCompensation,
                3: validateGoods,
                4: validateCreateCoupon,
                5: validateCheckOrder
            }
            res.data.robot_info.forEach((item, index) => {
                item.avatar = this.defaultAvatar;
                item.question.forEach(QItem => QItem.surplusErrCount = res.data.repeat_ask_num * 1);
                item.chatConfig = {
                    value: "",
                    countdown: res.data.wait_time,
                    timer: void 0,
                    unread: false,
                    isShow: false,
                    questionIndex: 0,
                    finished: false,
                    params: item.question.map((QItem, QIndex) => getNewQuestion(item, QIndex)),
                    template: [],
                    responseTime: 0,
                    lock: false,
                    async submitFinishRobot() {
                        _this.loading = true;
                        const res = await studentServiceSubmitRobotAPI({
                            robot_id: item.id,
                            content: JSON.stringify(this.params)
                        });
                        _this.loading = false;
                        if(validateRes(res, true)) {
                            this.finished = true;
                            _this.getAnswerStatus();
                            this.timer && clearInterval(this.timer);
                            this.countdown = 0;
                            this.value = "";
                        }
                    },
                    setCountdown() {
                        this.timer && clearInterval(this.timer);
                        this.timer = setInterval(() => {
                            this.countdown--;
                            if(this.countdown <= 0) {
                                clearInterval(this.timer);
                                this.submitFinishRobot();
                            }
                        }, 1000)
                    },
                    pushQuestion(answerValid) {
                        if(answerValid || item.question[this.questionIndex].surplusErrCount <= 0) {
                            this.params[this.questionIndex] = this.template.slice(-1)[0];
                            if(!this.params[this.questionIndex + 1]) {
                                this.submitFinishRobot();
                                return void 0;
                            }
                            this.questionIndex++;
                        }
                        this.lock = true;
                        setTimeout(() => {
                            this.template.push(Object.assign({}, this.params[this.questionIndex], { robot_ask_time: formatTime(+new Date()) }));
                            this.lock = false;
                            _this.$refs.chatWindow.bottomOut();
                        }, 1500);
                        if(_this.robotIndex != index) this.unread = true;
                    },
                    sendMsg(msg, operation) {
                        if(this.lock) {
                            _this.$message.warning("请等待机器人提问");
                            return void 0;
                        }
                        const question = this.template.slice(-1)[0];
                        const questionItem = item.question[this.questionIndex];
                        if(operation) {
                            if(!validateQuestionType(questionItem)) return false;
                        }else if(!this.value.trim()) {
                            _this.$message.warning("答案不能为空");
                            return false;
                        }else if(!_this.answerStatus.beBing) {
                            _this.$message.warning("请先开始考试");
                        }
                        const answerTime = +new Date();
                        question.stu_answer_time = formatTime(answerTime);
                        question.stu_post_time = getAnswerTime(question.robot_ask_time, answerTime);
                        question.stu_key_word = msg;
                        let answerValid;
                        if(questionItem.type == 2) {
                            answerValid = msg instanceof Object && questionItem.score_operate == operation && validateMap[operation](questionItem, msg);
                        }else {
                            answerValid = typeof msg == "string" && validateAnswer(questionItem, msg);
                        }
                        this.countdown = res.data.wait_time;
                        answerValid || questionItem.surplusErrCount--;
                        if(!operation) this.value = "";
                        this.responseTime = Math.floor(this.template.reduce((sum, item) => sum + item.stu_post_time * 1, 0) / this.template.length)
                        this.pushQuestion(answerValid);
                        _this.$refs.chatWindow.bottomOut();
                        return true;
                    }
                };
            })
            this.coreInfo = res.data;
        },
        async getAnswerStatus() {
            const res = await studentUsePaperStatus();
            if(!validateRes(res)) return void 0;
            this.answerStatus.status = res.data.status;
        },
        async setAnswerStatus(status, callback) {
            this.loading = true;
            let res = await studentUsePaperStatus({ status });
            this.loading = false;
            if(!validateRes(res)) return void 0;
            await this.getAnswerStatus();
            callback();
        },
        setReplyChatMessage(msg) {
            if(this.robotList[this.robotIndex] && this.robotList[this.robotIndex].chatConfig.finished) {
                this.$message.warning("机器人已下线无法作答");
                return void 0;
            }else if(!this.answerStatus.beBing) {
                this.$message.warning("请先开始考试");
                return void 0;
            }
            this.coreInfo.robot_info[this.robotIndex].chatConfig.value = msg;
        },
        async startAnswer() {
            this.setAnswerStatus(1, () => {
                if([1,3].includes(this.answerStatus.status)) {
                    this.robotIndex = 0;
                    this.answerStatus.beBing = true;
                    this.setRobotShowTimer();
                }
            })
        },
        setRobotShowTimer() {
            this.coreInfo.robot_info[0].chatConfig.isShow = true;
            this.coreInfo.robot_info[0].chatConfig.pushQuestion();
            this.coreInfo.robot_info[0].chatConfig.setCountdown();
            this.$nextTick(() => {
                this.timer.robotShowTimer = setInterval(() => {
                    const robotIndex = this.coreInfo.robot_info.findIndex(item => !item.chatConfig.isShow);
                    if(robotIndex == -1) {
                        clearInterval(this.timer.robotShowTimer);
                        return void 0;
                    };
                    const robot = this.coreInfo.robot_info[robotIndex];
                    robot.chatConfig.isShow = true;
                    robot.chatConfig.pushQuestion();
                    robot.chatConfig.unread = true;
                    robot.chatConfig.setCountdown();
                }, this.coreInfo.visit_interval * 1000);
            })
        },
        async submitPaper() {
            if(!this.answerStatus.beBing) {
                this.$router.back();
                return void 0;
            }
            const unfinishedList = this.coreInfo.robot_info.filter(item => !item.chatConfig.finished && item.chatConfig.isShow && item.chatConfig.params[0].stu_key_word);
            let res = await this.$confirm(`${unfinishedList.length > 0 ? '当前客服机器人还未完成答题，确定退出？退出后记得再次进入完成所有客服机器人的答题' : '是否提交试卷？'}`, '提示消息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(e => {});
            
            if(res) {
                if(unfinishedList.length > 0) {
                    const formData = new FormData();
                    const params = unfinishedList.map(item => ({ robot_id: item.id, content: item.chatConfig.params }));
                    formData.append("data", JSON.stringify(params));
                    res = await studentServiceSubmitUnfinishedRobot(formData);
                    if(!validateRes(res, true)) return void 0;
                }
                this.getAnswerStatus();
                this.$router.replace("/student/competition/index");
            }
        }
    },
    watch: {
        robotIndex: {
            handler(newVal, oldVal) {
                if(this.coreInfo.robot_info && this.coreInfo.robot_info[newVal] && this.coreInfo.robot_info[newVal].chatConfig.unread) {
                    this.coreInfo.robot_info[newVal].chatConfig.unread = false;
                }
            },
            immediate: true
        }
    },
    created() {
        _this = this;
        this.init();
        // this.startAnswer();
    },
    destroyed() {
        this.coreInfo.robot_info.forEach(item => item.chatConfig.timer && clearInterval(item.chatConfig.timer));
        this.timer.robotShowTimer && clearInterval(this.timer.robotShowTimer);
    }
}
</script>

<style scoped lang="scss">
.customer-service {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .containers {
        box-sizing: border-box;
        display: flex;
        flex: 1;
        /* width: 100%; */
        background-color: #f8f7fc;
        overflow: hidden;
        .main {
            flex: 1;
            display: flex;
            padding: 20px 20px 10px 10px;
            /* overflow: hidden; */
        }
    }
    .tab-containers {
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
    }
}
</style>
