<template>
    <div class="coupon" :class="{ already: status }">
        <div class="left-info">
            <div class="core-info">
                <div class="price">
                    <span class="decorate">￥</span>
                    <span>{{ coupon.price_jian }}</span>
                </div>
                <div class="type-info">
                    <div class="desc">满{{ coupon.price_man }}可用</div>
                    <div class="type">{{ typeList[coupon.coupon_id] }}</div>
                </div>
            </div>
            <div class="expiry-date">
                有效期至：{{ getTime() }}
            </div>  
        </div>
        <div class="line">
            <div class="line-item" v-for="(item, index) in [1,2,3,4,5,6,7]" :key="index"></div>
        </div>
        <div class="right-send" @click="sendCoupon">
            <template v-if="status">
                <div>已被</div>
                <div>领取</div>
            </template>
            <template v-else>
                <div>立即</div>
                <div>发送</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Coupon',
    components: {},
    props: {
        coupon: {
            type: Object,
            default: () => null
        },
        type: {
            type: Number,
            default: 0
        },
        status: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            typeList: ["商品优惠券", "店铺优惠券"],
        }
    },
    computed: {},
    methods: {
        sendCoupon() {
            this.$emit("sendCoupon", this.coupon);
        },
        getTime() {
            const createTime = ["当日23:59:59前使用有效", "次日23:59:59前使用有效"];
            return this.coupon.score_operate == 4 ? createTime[this.coupon.validity_time - 1] : `${this.coupon.start_time.split(" ")[0]}~${this.coupon.end_time.split(" ")[0]}`
        }
    }
}
</script>

<style scoped lang="scss">
.coupon {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 112px;
    padding: 13px 0;
    color: #E8392C;
    background-color: #FDF6F2;
    border-radius: 4px;
    margin-top: 10px;
    .left-info {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        .core-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .price {
                margin-right: 9px;
                font-size: 38.25px;
                font-weight: bold;
                .decorate {
                    margin-right: 2px;
                    font-size: 17.71px;
                }
            }
            .type-info {
                font-size: 17.57px;
                font-weight: bold;
                .type {
                    margin-top: 2px;
                    font-weight: normal;
                    font-size: 13.24px;
                }
            }
        }
        .expiry-date {
            margin-bottom: 4px;
            font-size: 13.24px;
            text-align: center;
        }
    }
    .line {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 1px;
        height: 100%;
        margin-left: 3%;
        .line-item {
            width: 100%;
            height: 4px;
            background-color: #E8392C;
        }
    }
    .right-send {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
        cursor: pointer;
    }
    &.already {
        background-color: #E8E8E8;
        .left-info,
        .right-send {
            color: #A5A5A5;
        }
        .line .line-item{
            background-color: #A5A5A5;
        }
    }
}
</style>
