<template>
    <div class="rich-text-img">
        <i class="el-icon-picture" :class="{ disabled: once && lock }" @click="openFileSelector"></i>
        <input type="file" class="file-input" ref="file" accept="image/*" @input="fileChange">
    </div>
</template>

<script>
import { uploadReplyImg } from "../../../../utils/apis"
export default {
    name: 'RichTextImg',
    components: {},
    props: {
        params: {
            type: String,
            default: "",
        },
        tinymceId: {
            type: String,
            default: ""
        },
        once: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            lock: false
        }
    },
    inject: ["validateRes"],
    computed: {},
    methods: {
        openFileSelector() {
            if(this.once && this.lock) {
                this.$message.warning("只能上传一张图片");
                return void 0;
            }
            this.$refs.file && this.$refs.file.click();
        },
        async fileChange(e) {
            const file = e.target.files[0];
            const maxSize = 1024 * 1024 * 5;
            let errMsg = "";
            if(!file) {
                errMsg = "未选择文件";
            }else if(file.size > maxSize) {
                errMsg = "选择的图片大小不能大于5M"
            }
            if(errMsg) {
                this.$message.warning(errMsg);
                return void 0;
            }
            const formData = new FormData();
            formData.append("img", file);
            const res = await uploadReplyImg(formData);
            if(this.validateRes(res, true)) {
                e.target.value = "";
                this.updateHandler(res.data[0]);
            }
        },
        updateHandler(src) {
            const richText = tinymce.get(this.tinymceId);
            if(!richText) return void 0;
            if(this.once && this.lock) {
                this.$message.warning("只能上传一张图片");
                return void 0;
            }
            const img = `<img src="${src}" class="save-img" style="max-width: 80px; max-height: 80px; vertical-align: bottom;" />`
            richText.execCommand("mceInsertContent", false, img);
            this.$emit("update:params", src);
            this.lock = true;
        }
    }
}
</script>

<style scoped lang="scss">
.rich-text-img {
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
    .el-icon-picture {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #D7D7D7;
        font-size: 16px;
        transition: color .3s;
        &:hover {
            color: #006aff;
        }
        &.disabled {
            color: #eeeddd;
            cursor: not-allowed;
        }
    }
    .file-input {
        display: none;
    }
}
</style>
